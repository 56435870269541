import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Card from "../../components/card/Card";
import { Form } from "../../components/form/Form";
import { SubmitButton } from "../../components/button/CustomButton";
import { HTTPStatusResponse } from "../../config/global";
import { APP_API_URL } from "../../config/api";
import { useMutate } from "../../hooks/useMutations";
import "../../assets/styles/tombola.css";
import { ColumnInterface } from "../../components/table/typings";
import moment from "moment";
import Table from "../../components/table/Table";
import { useState, useEffect } from "react";
import { Select2 } from "../../components/form/Select";
import { FaInfoCircle } from "react-icons/fa";
import { useQueries } from "../../hooks/useQueries";
import Confetti from "react-confetti";
import ToggleButton from "../../components/button/ToggleButton";

export default function BigTombolaPage() {
  /**
   * Hooks
   */
  const [results, setResults] = useState([]);
  const [isSend, setIsSend] = useState(false);
  const [animateBall, setAnimateBall] = useState(false);
  const [isConfettiVisible, setConfettiVisible] = useState(false);
  const currentMonth = Number(moment().format("M"));
  const currentDay = Number(moment().format("D"));
  const openApp = true;//currentMonth === 10 && currentDay === 26;

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchType = watch("type", null);
  const watchSalesPoint = watch("salesPointId", null);
  const watchTown = watch("town", null);

  const selectedOption = watchType ? watchType.name : null;

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(
    APP_API_URL + `tombola-big-store`,
    "post"
  );

  const { data: salesPoints, isLoading: isLoadingSalepoints } = useQueries(
    APP_API_URL + `sales-points?paginate=0`,
    ["salesPoints"]
  );

  const { data: lots, isLoading: isLoadingLot } = useQueries(
    APP_API_URL +
      `lot-find?${
        watchSalesPoint
          ? `salesPointId=${watchSalesPoint ? watchSalesPoint?.id : ""}`
          : `town=${watchTown ? watchTown?.name : ""}`
      }`,
    ["lots", watchTown, watchSalesPoint]
  );
  /**
   * Function
   */
  const handleSubmitForm = (values: any) => {
    setResults([]);
    setAnimateBall(true);
    const salesPointsId = values.salesPointId ? values.salesPointId.id : null;
    const townName = values.town ? values.town.name : null;
    const lotName = values.lot ? values.lot.name : null;
    mutateAsync({
      ...values,
      town: townName,
      salesPointId: salesPointsId,
      lot: lotName,
      isSend: isSend ? 1 : 0,
    }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        toast.success("Tirage éffectué !");
        setConfettiVisible(true);
        setTimeout(() => {
          setConfettiVisible(false);
        }, 10000);
        setResults(response.data.data ?? []);
      } else {
        toast.error(response?.data.message);
      }
      setAnimateBall(false);
    });
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Code gagnant",
      key: "code",
      dataIndex: "code",
    },
    {
      title: "Nom du client",
      key: "client",
      dataIndex: "client",
      render: (element: any) => <>{element.client.toUpperCase()}</>,
    },
    {
      title: "Site",
      key: "town",
      dataIndex: "town",
    },
    {
      title: "Numéro de téléphone",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Date de validation du ticket",
      key: "win_date",
      render: (element: any) => (
        <>{moment(element.win_date).format("YYYY-MM-DD HH:mm:ss")}</>
      ),
    },
  ];

  useEffect(() => {
    setValue("town", null);
    setValue("salesPointId", null);
    setValue("lot", null);
    setResults([]);
  }, [watchType]);

  useEffect(() => {
    setValue("town", null);
    setValue("lot", null);
  }, [watchSalesPoint]);

  useEffect(() => {
    setValue("salesPointId", null);
    setValue("lot", null);
  }, [watchTown]);

  /**
   * UI
   */
  return (
    <>
      <div className="relative">
        {!openApp ? (
          <div className="fixed inset-0 bg-white bg-opacity-95 pointer-events-none z-10 flex items-center justify-center">
            <p className="text-red-500 text-4xl uppercase text-center">
              <FaInfoCircle className="mx-auto" size={45} />
              <span>La Grande tombola sera disponible le 26 novembre 2023</span>
            </p>
          </div>
        ) : (
          <>
            <div className="grid md:grid-cols-2 gap-4">
              <Card title={"Tombola"}>
                <div className="mx-auto px-4 border border-gray-200 rounded-full w-[400px] h-[400px] border-b-[100px] border-t-[#fff] border-t-[25px] relative flex items-center">
                  <div
                    className={`rounded-full h-[400px] p-10 relative w-full ${
                      animateBall ? "animate" : ""
                    }`}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value: number) => (
                      <span key={value} className="loader-tombola">
                        <span>{value}</span>
                      </span>
                    ))}
                  </div>
                </div>
              </Card>
              <Form
                loading={isLoading}
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <Card
                  title="Lancer la Giga Tombola"
                  extras={[
                    isLoadingSalepoints || isLoadingLot ? (
                      <span
                        key={"onload"}
                        className="text-primary text-xl font-bold"
                      >
                        Les données sont en cours de chargement, patientez svp.
                      </span>
                    ) : (
                      <SubmitButton
                        value="Démarrer"
                        loading={isLoading}
                        className="disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white"
                        key="submit"
                      />
                    ),
                  ]}
                >
                  <div className={"mb-5 grid grid-cols-2 gap-4"}>
                    <div>
                      <Select2
                        placeholder="Sélectionner"
                        control={control}
                        name={"type"}
                        error={errors?.type}
                        register={register}
                        textKeyItem={"name"}
                        valueKeyItem={"id"}
                        items={[
                          { id: 1, name: "VILLE" },
                          { id: 2, name: "POINT DE VENTE" },
                        ]}
                        label="Tombola par :"
                      />
                    </div>
                    {selectedOption === "VILLE" && (
                      <div>
                        <Select2
                          placeholder="Selectionner la ville"
                          control={control}
                          name={"town"}
                          error={errors?.town}
                          register={register}
                          textKeyItem={"name"}
                          valueKeyItem={"id"}
                          items={[
                            { id: 1, name: "DOUALA" },
                            { id: 2, name: "YAOUNDÉ" },
                          ]}
                          label="Ville"
                        />
                      </div>
                    )}
                    {selectedOption === "POINT DE VENTE" && (
                      <div>
                        <Select2
                          placeholder="Selectionner le point de vente"
                          control={control}
                          name={"salesPointId"}
                          error={errors?.salesPointId}
                          register={register}
                          textKeyItem={"name"}
                          valueKeyItem={"id"}
                          items={salesPoints?.data.data}
                          label="Point de vente"
                        />
                      </div>
                    )}
                    <div>
                      <Select2
                        placeholder="Selectionner le lot"
                        control={control}
                        name={"lot"}
                        error={errors?.lot}
                        register={register}
                        textKeyItem={"name"}
                        valueKeyItem={"id"}
                        items={lots?.data.data}
                        label="Lot"
                      />
                    </div>
                    <div className="flex gap-2 items-center">
                      <span>Envoi automatique du sms</span>
                      <ToggleButton
                        name={"isSend"}
                        enabled={isSend}
                        disabled={isLoading}
                        onClick={() => setIsSend(!isSend)}
                      />
                    </div>
                  </div>
                  <Table
                    tableClassName="text-left"
                    columns={columns}
                    loading={false}
                    datas={results}
                  />
                </Card>
              </Form>
            </div>
          </>
        )}
      </div>
      {isConfettiVisible && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={1000}
          recycle={true}
          tweenDuration={10000}
          style={{ zIndex: 9999 }}
        />
      )}
    </>
  );
}
